<template>
  <section id="BloqueUnoPrincipal">
    <FondoVideo :datosVideo="datos.datos_video"/>

    <div class="linea__izquierda" style="background-color: #50B162;"></div>
    <svg class="svg_fondo svg_fondo--epil" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1058.74 1020.27"
      preserveAspectRatio="xMaxYMid slice" :style="`fill: #50B162;`">
      <g>
        <polygon points="0 0 1058.74 0 771.54 1020.27 0 1020.27 0 0" />
      </g>
    </svg>

    <section class="contenedor__bloqueUno">
      <picture>
        <source srcset="https://d3jotkb1j9zbaa.cloudfront.net/logo_white.webp" type="image/webp" />
        <img loading="lazy" class="logo__bayer" width="77" height="77"
          src="https://d3jotkb1j9zbaa.cloudfront.net/logo_white.png" alt="Logo menú Bayer"
          title="Logo primer bloque Bayer" />
      </picture>

      <div class="contenido__introduccion">
        <p class="introduccion__titulo">{{datos.titulo}}</p>
        <p class="introduccion__texto--epil" v-for="texto in contenido" :key="texto" v-html="texto"></p>
      </div>
    </section>

    <div class="linea__derecha" style="background-color: #50B162;"></div>
  </section>
</template>

<script>
import { defineAsyncComponent, ref } from "vue";

export default {
  name: "BloqueUnoEpil",
  components: {
    FondoVideo: defineAsyncComponent(() =>
      import(/* webpackChunkName: "FondoVideo" */ "@/components/video/fondoVideo.vue")
    ),
  },
  props: {
    bloque_uno: Object,
  },
  setup(props) {
    const datos = props.bloque_uno
    const contenido = [...datos.titulo_texto];
    Array.from(datos.titulo_destacada).forEach((element) => {
      Array.from(contenido).forEach((texto) => {
        var textoCorrecto = texto.replace(element, `<span class='texto__destacado'>${element}</span>`);
        var index = contenido.indexOf(texto)
        contenido[index] = textoCorrecto;
      })
    });
    return { datos, contenido};
  },
};
</script>
